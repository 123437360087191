<template>
  <div>
    <b-modal
      id="modal-calendar-appointment-context-menu"
      v-model="appointmentContextMenuModal.show"
      :no-close-on-backdrop="false"
      :hide-footer="true"
      :hide-header="false"
      modal-class="modal-primary"
      centered
      size="sm"
      @hide="hideAppointmentContextMenuModal"
    >
      <template #modal-header>
        <h4>
          {{ appointmentContextMenuModal.details.services[0].name }} -
          {{ appointmentContextMenuModal.details.customer.name }}
          {{ appointmentContextMenuModal.details.customer.lastName }}
        </h4>
        <p class="mb-0">
          {{ appointmentStart }}
        </p>
      </template>
      <!-- BODY -->
      <b-dropdown
        id="appointmentStateDropdown"
        class="mb-1"
        variant="primary"
        block
      >
        <template #button-content>
          Change status
        </template>

        <b-dropdown-item
          disabled
          :data-to="appointmentContextMenuModal.details.state"
        >
          <span
            class="transition-name"
          >
            {{ transitionStateNames[appointmentContextMenuModal.details.state] }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item
          v-for="(transition, key) in transitions"
          :key="key"
          :data-to="transition.to"
          @click="changeState(transition.name)"
        >
          <span class="transition-name">{{
            transitionStateNames[transition.to]
          }}</span>
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        block
        variant="danger"
        @click="editAppointmentHideContextMenuModal"
      >
        Edit
      </b-button>

      <b-button
        v-if="appointmentContextMenuModal.details.state === 'confirmed'"
        block
        variant="primary"
        @click="showAppointmentCheckoutModal"
      >
        Checkout
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BModal, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'StylistCalendarAppointmentContextMenuModal',
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      appointmentStart: '',
      transitionStateNames: {
        pending: 'Pending',
        confirmed: 'Confirm',
        paid: 'Paid',
        show: 'Show',
        no_show: 'No Show',
        in_progress: 'Start',
        cancelled: 'Cancel',
      },
      transitions: [],
    }
  },
  computed: {
    ...mapState('StylistCalendarStoreModule', {
      appointmentContextMenuModal: state => state.appointmentContextMenuModal,
      appointmentStateExtraConfirmToChargeModal: state => state.appointmentStateExtraConfirmToChargeModal,
    }),
  },
  mounted() {
    this.appointmentStart = moment(
      this.appointmentContextMenuModal.details.startsAt,
    ).format('MMMM Do YYYY, h:mm:ss a')
    this.fetchTransitions()
  },
  methods: {
    ...mapActions('StylistCalendarStoreModule', [
      'fetchAppointmentTransitions',
      'changeAppointmentState',
    ]),
    hideAppointmentContextMenuModal() {
      this.$store.dispatch(
        'StylistCalendarStoreModule/hideAppointmentContextMenuModal',
      )
    },
    showAppointmentModal(appointment) {
      this.$store.dispatch(
        'StylistCalendarStoreModule/showAppointmentModal',
        appointment,
      )
    },
    showAppointmentCheckoutModal() {
      this.$store.dispatch('StylistCalendarStoreModule/showAppointmentCheckoutModal')
    },
    editAppointmentHideContextMenuModal() {
      this.$store.dispatch(
        'StylistCalendarStoreModule/showAppointmentModal',
        this.appointmentContextMenuModal.details,
      )
      this.hideAppointmentContextMenuModal()
    },
    changeState(transition) {
      const appointmentId = this.appointmentContextMenuModal.details.id
      const { state } = this.appointmentContextMenuModal.details
      const statesExtraConfirmToCharge = ['cancel', 'no_show']

      if (statesExtraConfirmToCharge.includes(transition) && state === 'confirmed') {
        this.showAppointmentStatusExtraConfirmToChargeModal(transition)
        return
      }

      this.changeAppointmentState({
        appointmentId,
        transition,
      }).then(() => {
        this.$store.dispatch('StylistCalendarStoreModule/fetchAppointments')
        this.hideAppointmentContextMenuModal()
      })
    },
    showAppointmentStatusExtraConfirmToChargeModal(status) {
      this.$store.dispatch(
        'StylistCalendarStoreModule/showAppointmentStatusExtraConfirmToChargeModal',
        status,
      )
    },
    fetchTransitions() {
      this.fetchAppointmentTransitions(this.appointmentContextMenuModal.details.id)
        .then(response => {
          this.transitions = response.map(transition => ({
            name: transition.name,
            from: transition.froms,
            to: transition.tos[0],
          }))
        })
    },
  },
}
</script>

<style lang="scss">
@import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_flat.css";
@import "src/assets/scss/variables/state-color-codes.scss";
.modal-header {
  display: block;
}

@each $state, $color in $state-colors {
  #appointmentStateDropdown .dropdown-item[data-to="#{$state}"]::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: $color;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
  }
}
</style>
