<template>
  <div>
    <b-overlay
      :show="appointmentsStore.isPending || createAppointmentStore.isPending"
      bg-color="#ffffff"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="tableConfig.perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <!-- Search & Add appointment -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="tableConfig.searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  class="mr-1"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="createAppointmentModal()"
                >
                  <span class="text-nowrap">Add appointment</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="appointmentsStore.response"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="tableConfig.sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="tableConfig.isSortDirDesc"
        >
          <!-- Column: Starts at -->
          <template #cell(date)="data">
            <span
              class="btn-link cursor-pointer"
              @click="$router.push({name: appointmentShow, params: { id: data.item.id }})"
            >{{ data.item.startsAt | dateAndHoursUsLocale }} - {{ data.item.endsAt | hoursUsLocale }}</span>
          </template>
          <!-- Column: Services -->
          <template #cell(services)="data">
            {{ getServicesName(data.item.services) }}
          </template>
          <!-- Column: customer name -->
          <template #cell(customerName)="data">
            {{ data.item.customer.name }} {{ data.item.customer.lastName }}
          </template>
          <!-- Column: customer phone -->
          <template #cell(customerPhone)="data">
            {{ data.item.customer.phone }}
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              :variant="getVariant(data.item.status)"
              @click="showAppointmentContextMenuModalWindow(data.item)"
            >
              {{ bookingStatusDictionary[data.item.status] }}
            </b-badge>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ tableItems.from }} to {{ tableItems.to }} of {{ tableItems.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableConfig.currentPage"
                :total-rows="appointmentsStore.totalItems"
                :per-page="tableConfig.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <stylist-calendar-appointment-modal v-if="appointmentModalStore.show" />
    <stylist-calendar-appointment-context-menu-modal v-if="appointmentContextMenuModal.show" />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BBadge,
  BPagination, BFormInput, BOverlay,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import vSelect from 'vue-select'
import BookingStatusDictionary from '@/dictionaries/bookingStatusDictionary'
import StylistCalendarAppointmentModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentModal.vue'
import defaultAppointmentCalendarEvent from '@/components/stylist/stylist-calendar/defaultAppointmentCalendarEvent'
import StylistCalendarAppointmentContextMenuModal from '@/components/stylist/stylist-calendar/StylistCalendarAppointmentContextMenuModal.vue'
import { APPOINTMENT_SHOW } from '@/router/routes/routes-names'

export default {
  filters: {
    dateAndHoursUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('MMMM D, yyyy h:mm a')
    },
    hoursUsLocale(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('h:mm a')
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BOverlay,
    BBadge,
    BButton,
    StylistCalendarAppointmentModal,
    StylistCalendarAppointmentContextMenuModal,
    vSelect,
  },
  data() {
    return {
      appointmentShow: APPOINTMENT_SHOW,
      perPageOptions: [10, 25, 50, 100],
      tableItems: {
        from: 0,
        to: 0,
        of: 0,
      },
      tableConfig: {
        currentPage: 1,
        perPage: 10,
        sortBy: 'startsAt',
        isSortDirDesc: true,
        searchQuery: null,
      },
      tableColumns: [
        { key: 'date', sortable: true },
        { key: 'services', sortable: false },
        { key: 'customerName', sortable: false },
        { key: 'customerPhone', sortable: false },
        { key: 'status', sortable: true },
      ],
      bookingStatusDictionary: BookingStatusDictionary,
      bookingStatuses: [],
    }
  },
  computed: {
    ...mapState('StylistAppointmentStoreModule', {
      appointmentsStore: state => state.appointments,
    }),
    ...mapState('StylistCalendarStoreModule', {
      appointmentModalStore: state => state.appointmentModal,
      appointmentContextMenuModal: state => state.appointmentContextMenuModal,
      createAppointmentStore: state => state.appointments,
    }),
  },
  watch: {
    tableConfig: {
      deep: true,
      handler() {
        this.getAppointments()
      },
    },
    createAppointmentStore: {
      deep: true,
      handler(newAppointment) {
        if (newAppointment.response.length > 0) {
          this.getAppointments()
        }
      },
    },
  },
  created() {
    this.getAppointments()
    this.bookingStatuses = this.mapStatuses(BookingStatusDictionary)
  },
  methods: {
    ...mapActions('StylistAppointmentStoreModule', [
      'fetchAppointments',
    ]),
    ...mapActions('StylistCalendarStoreModule', [
      'showAppointmentModal',
      'showAppointmentContextMenuModal',
    ]),
    showAppointmentContextMenuModalWindow(appointment) {
      this.$set(appointment, 'start_date', moment(appointment.startsAt, 'yyyy-MM-DDTHH:mm:ss').format('yyyy-MM-DD HH:mm:ss'))
      this.showAppointmentContextMenuModal(appointment)
    },
    getServicesName(services) {
      let servicesName = ''
      services.forEach(service => {
        servicesName += `${service.name} `
      })
      return servicesName
    },
    getAppointments() {
      this.fetchAppointments(this.getQueryParams()).then(() => this.calculateItems())
    },
    getVariant(status) {
      switch (status) {
        case this.bookingStatuses.confirmed:
        case this.bookingStatuses.show:
        case this.bookingStatuses.in_progress:
          return 'light-success'
        case this.bookingStatuses.cancelled:
          return 'light-dark'
        case this.bookingStatuses.no_show:
          return 'light-danger'
        case this.bookingStatuses.paid:
          return 'light-info'
        default:
          return 'light-primary'
      }
    },
    mapSortBy(column) {
      switch (column) {
        case 'date':
          return 'startsAt'
        default:
          return column
      }
    },
    getQueryParams() {
      return {
        itemsPerPage: this.tableConfig.perPage,
        page: this.tableConfig.currentPage,
        search: this.tableConfig.searchQuery,
        [`order[${this.mapSortBy(this.tableConfig.sortBy)}]`]: this.tableConfig.isSortDirDesc ? 'desc' : 'asc',
      }
    },
    calculateItems() {
      const localItemsCount = this.appointmentsStore.response ? this.appointmentsStore.response.length : 0
      this.tableItems = {
        from: this.tableConfig.perPage * (this.tableConfig.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.tableConfig.perPage * (this.tableConfig.currentPage - 1) + localItemsCount,
        of: this.appointmentsStore.totalItems,
      }
    },
    createAppointmentModal() {
      const appointment = { ...defaultAppointmentCalendarEvent, start_date: moment().format('YYYY-MM-DD HH:mm') }
      this.showAppointmentModal(appointment)
    },
    mapStatuses(bookingStatusDictionary) {
      const bookingStatuses = []
      Object.keys(bookingStatusDictionary).forEach(object => {
        bookingStatuses[object] = object
      })
      return bookingStatuses
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
